.training-records-table-component-wrapper {
  padding: 15px;
  background-color: white;
}
.training-records-table-component-wrapper .top-title-bar {
    height: 35px;
    border-bottom: 1px solid #E6E6E6;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: left;
}
.training-records-table-component-wrapper .table-search-group {
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.training-records-table-component-wrapper .table-search-group .item-down .el-scrollbar__wrap {
      margin-bottom: 0px !important;
}
.training-records-table-component-wrapper .table-search-group .search-right {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-right: 40px;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.training-records-table-component-wrapper .switch-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: left;
}
.training-records-table-component-wrapper .table-content-wrapper {
    margin-top: 20px;
}
.training-records-table-component-wrapper .table-content-wrapper .train-record-wrapper .el-table-row-throw {
      color: red;
}
.training-records-table-component-wrapper .table-content-wrapper .operation-record-wrapper .face-img {
      width: 60px;
      height: 60px;
}
.training-records-table-component-wrapper .source-pagination {
    margin-top: 40px;
}
.records-item-down .el-scrollbar__wrap {
  margin-bottom: 0px !important;
}
